import React from 'react'
import ReactPlayer from 'react-player'

export default function Player() {
    return (
        <div>
            <ReactPlayer
                url="http://content.jwplatform.com/manifests/vM7nH0Kl.m3u8"
                controls={true}
                width="100%"
                height="auto"
            />
        </div>
    )
}
