import React from 'react'
import Player from './Player'

export default function App() {
  return (
    <div>
      <Player />
    </div>
  )
}
